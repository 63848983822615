import CartStatus from './CartStatus';
import Customer from './Customer';

export enum CommentTagType {
  None = 0,
  FirstNewValidComment = 100,
  SecondNewValidCommentOnSamePost = 101,
}

export default interface Comment {
  message?: string;
  createdTime?: string;
  customer?: Customer;
  cart?: {
    id?: string;
    cartStatus?: CartStatus;
  };
  id?: string;
  processStatus?: CommentProcessStatus;
  avatar: {
    url: string;
  };
  commentTags?: CommentTagType[];
  printedAt?: string;
}

export enum CommentProcessStatus {
  IgnoreManualComment = -6,
  IgnoreCommentWithNotFoundPost = -5,
  IgnoreCommentWithNotFoundPage = -4,
  IgnorePageComment = -3,
  IgnorePageCreator = -2,
  IgnoreCommentCreateBeforeSyncTime = -1,
  None = 0,
  CartCreated = 1,
  CartManualCreated = 2,
  BidCreated = 3,
  WelcomeMessageSent = 4,
  CommentParseFailed = 100,
  CommentParseQuantityFailed = 101,
  ProductNotFound = 200,
  ProductNotAssociatedToPost = 201,
  ProductOutOfStock = 203,
  ProductReachedMaxQuantity = 204,
  ProductInactive = 205,
  FillupProductUnavailable = 206,
  CustomerBlacklisted = 500,
}

export type SubmittedComment = {
  id: string;
  message: string;
  createdTime: string;
  customerName: string;
  avatar: {
    url: string | null;
  };
};

export enum CommentStatusRequest {
  All = 1,
  CartCreated = 2,
  OutOfStock = 3,
  ProductDeactivated = 4,
  ReachedPurchaseLimit = 5,
  NonKeyword = 6,
}
