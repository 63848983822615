import { Status as LiveStreamStatus, PostCapturingSession } from 'models/Session';

export { LiveStreamStatus };

export interface SetupLiveData {
  name: string;
  productIds?: string[];
  postId?: string;
  applyOn?: CapturingSessionApplyOn;
  isSaveAsDraft?: boolean;
}

export type LiveSession = {
  id: string;
  name: string;
  status: LiveStreamStatus;
  created: string;
  post?: PostCapturingSession;
  productIds: string[];
};

export type AppsyncEvent = {
  eventType: AppsyncEventType;
  productId: string;
  remainStockChanged?: number;
  postId?: string;
  masterProductId?: string;
};

export enum AppsyncEventType {
  ProductRemainStockChangedDomainEvent = 'ProductRemainStockChangedDomainEvent',
  ProductSoldAndReservedChangedDomainEvent = 'ProductSoldAndReservedChangedDomainEvent',
}

export enum CapturingSessionApplyOn {
  AllProducts,
  SpecificProducts,
}

export interface LabelInformationSetting {
  customerName?: boolean;
  comment?: boolean;
  pageName?: boolean;
  address?: boolean;
  product?: boolean;
  phoneNumber?: boolean;
  subTotal?: boolean;
}

export interface LabelSizeSetting {
  width?: number;
  height?: number;
  fontSize?: number;
}

export interface PrintCommentSetting {
  labelInformation?: LabelInformationSetting;
  labelSize?: LabelSizeSetting;
}
